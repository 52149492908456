import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import classnames from 'classnames'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Container from '~components/globals/Container'
import * as styles from './style.module.scss'

const SubscribeModal = ({ isOpen, onClose, onSubscribe, data, isCompact = false }) => {
  const [formSent, setFormSent] = useState(false)
  const [errorText, setErrorText] = useState(null)
  const [isValid, setIsValid] = useState(false)
  const emailEl = useRef(null)
  const checkbox = useRef(null)

  const { headline, subline, image, email_placeholder, consent, cta_text, success_text } = data || {}

  useEffect(() => {
    if (isOpen && !isCompact) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    // Reset form state when modal closes
    setFormSent(false)
    setErrorText(null)
    setIsValid(false)
    if (emailEl.current) emailEl.current.value = ''
    if (checkbox.current) checkbox.current.checked = false

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen, isCompact])

  const checkValidity = () => {
    const emailValid = emailEl.current?.validity.valid
    const checkboxChecked = checkbox.current?.checked
    setIsValid(emailValid && checkboxChecked)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const email = emailEl.current.value

    try {
      const result = await addToMailchimp(email)
      if (result.result === 'success') {
        setFormSent(true)
        setErrorText(null)
        if (onSubscribe) {
          onSubscribe()
        }
      } else {
        setFormSent(false)
        setErrorText(result.msg)
      }
    } catch (error) {
      setErrorText('Something went wrong. Please try again.')
    }
  }

  const handleManualClose = () => {
    onClose()
  }

  const submitButtonClass = classnames(styles.submitButton, {
    [styles.isValid]: isValid,
  })

  if (!isOpen) return null

  return (
    <div className={classnames(styles.modalOverlay, { [styles.compact]: isCompact })} onClick={handleManualClose}>
      <div
        className={classnames(styles.modalContent, { [styles.compact]: isCompact })}
        onClick={e => e.stopPropagation()}
      >
        <button className={styles.closeButton} onClick={handleManualClose} aria-label="Close modal">
          ×
        </button>
        <Container extraClassName={styles.container}>
          {!isCompact && (
            <div className={styles.imageContainer}>
              <img className={styles.image} src={image.url} alt={image.alt} />
            </div>
          )}
          <div className={classnames(styles.textContainer, { [styles.fullWidth]: isCompact })}>
            <div className={classnames(styles.formContent, { [styles.hidden]: formSent })}>
              <div className={styles.title}>{parse(headline?.html || '')}</div>
              <div className={styles.description}>{parse(subline?.html || '')}</div>
              <form className={styles.form} action="" onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={styles.input}
                    ref={emailEl}
                    onChange={checkValidity}
                    required
                  />
                  <div className={styles.highlight}></div>
                  <label htmlFor="email" className={styles.label}>
                    {email_placeholder}
                  </label>
                </div>
                {errorText && <div className={styles.error}>{parse(errorText)}</div>}
                <div className={styles.conditionsGroup}>
                  <input
                    className={styles.checkbox}
                    id="data"
                    required="required"
                    onChange={checkValidity}
                    type="checkbox"
                    ref={checkbox}
                  />
                  <label className={styles.checkboxLabel} htmlFor="data">
                    {parse(consent?.html || '')}
                  </label>
                </div>
                <button className={submitButtonClass} type="submit">
                  {cta_text}
                </button>
              </form>
            </div>
            <div className={classnames(styles.successContainer, { [styles.visible]: formSent })}>
              <div className={styles.success}>
                <div className={classnames(styles.successCopy, { [styles.visible]: formSent })}>{success_text}</div>
                <button
                  onClick={handleManualClose}
                  className={classnames(styles.closeSuccessButton, { [styles.visible]: formSent })}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

SubscribeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubscribe: PropTypes.func,
  isCompact: PropTypes.bool,
  data: PropTypes.shape({
    headline: PropTypes.shape({
      html: PropTypes.string,
    }),
    subline: PropTypes.shape({
      html: PropTypes.string,
    }),
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
    email_placeholder: PropTypes.string,
    consent: PropTypes.shape({
      html: PropTypes.string,
    }),
    cta_text: PropTypes.string,
    success_text: PropTypes.string,
  }).isRequired,
}

export default SubscribeModal
